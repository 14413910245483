import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import PhoneInput from "react-phone-input-2";

import { contactSAS } from "../../../../services/ContactUsService";

function ContactUs(props) {
  // Contact Us Section Screen
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOnContactUsSectionScreen, setIsOnContactUsSectionScreen] =
    useState(false);
  const [cHeadingClass, setCHeadingClass] = useState("");
  const [cSubHeadingClass, setSubHeadingClass] = useState("");
  const [phoneClass, setPhoneClass] = useState("");
  const [addressClass, setAddressClass] = useState("");
  const [emailClass, setEmailClass] = useState("");
  const [cHeading2Class, setCHeading2Class] = useState("");
  const [cSubHeading2Class, setSubHeading2Class] = useState("");
  const [contactFormClass, setContactFormClass] = useState("");

  const [contactMessage, setContactMessage] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    country: "",
    note: "",
  });

  const handleWayPointEnterContactUsSection = () => {
    setIsOnContactUsSectionScreen(true);
    setTimeout(() => {
      setCHeadingClass("animateSlideUp");
    }, 200);
    setTimeout(() => {
      setSubHeadingClass("animateSlideUp");
    }, 400);
    setTimeout(() => {
      setPhoneClass("animateFadeIn");
    }, 1000);
    setTimeout(() => {
      setAddressClass("animateFadeIn");
    }, 1200);
    setTimeout(() => {
      setEmailClass("animateFadeIn");
    }, 1400);
    setTimeout(() => {
      setCHeading2Class("animateSlideUp");
    }, 200);
    setTimeout(() => {
      setSubHeading2Class("animateSlideUp");
    }, 400);
    setTimeout(() => {
      setContactFormClass("animateFadeIn");
    }, 1000);

    // console.log(selectedTab);
  };

  const handleChange = (e) => {
    setContactMessage((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    if (!contactMessage.name) {
      setErrorMessage("Name is required.");
      return;
    }

    if (!contactMessage.email) {
      setErrorMessage("Email is required.");
      return;
    }

    if (!contactMessage.phone) {
      setErrorMessage("Phone is required.");
      return;
    }

    if (contactMessage.phone.length < 8 || contactMessage.phone.length > 15) {
      setErrorMessage("Phone length must be between 8 and 15 digits.");
      return;
    }

    if (!contactMessage.note) {
      setErrorMessage("Note is required.");
      return;
    }

    try {
      setLoading(true);
      setErrorMessage("");

      await contactSAS(contactMessage);

      setIsMessageSent(true);

      setContactMessage({
        ...contactMessage,
        name: "",
        email: "",
        phone: "",
        companyName: "",
        country: "",
        note: "",
      });
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div ref={props.thisRef}>
      {/* <!-- Contact Us Section --> */}
      <Waypoint onEnter={handleWayPointEnterContactUsSection}>
        <div id="contact-section" className="contact-us-content">
          <h2
            id="c-heading"
            className={
              isOnContactUsSectionScreen
                ? "text-center " + cHeadingClass
                : "text-center"
            }
            style={{ opacity: 0 }}
          >
            Contact Us
          </h2>
          <p
            id="c-sub-heading"
            className={isOnContactUsSectionScreen && cHeadingClass}
            style={{
              opacity: 0,
            }}
          >
            We are efficient, on time, and cost effective. We are with you from
            start to finish.
          </p>
          <div className="contact-information row">
            <div
              id="phone"
              className={
                +isOnContactUsSectionScreen
                  ? "col-sm-4 container " + phoneClass
                  : "col-sm-4 container"
              }
            >
              <span className="custom-icon">
                <i className="fa fa-phone"></i>
              </span>
              <h3>Phone</h3>
              <p>‪(929) 269-2542</p>
            </div>
            <div
              id="address"
              className={
                isOnContactUsSectionScreen
                  ? "col-sm-4 container " + addressClass
                  : "col-sm-4 container"
              }
            >
              <span className="custom-icon">
                <i className="fa fa-map-marker-alt"></i>
              </span>
              <h3>Company Address</h3>
              <p>61 S Baldwin Ave Suite 110 Sierra Madre, CA 91024</p>
            </div>
            <div
              id="email"
              className={
                "col " + isOnContactUsSectionScreen
                  ? "col-sm-4 container " + emailClass
                  : "col-sm-4 container"
              }
            >
              <span className="custom-icon">
                <i className="far fa-envelope"></i>
              </span>
              <h3>Email</h3>
              <a href="mailto:info@softwarealgo.com">info@softwarealgo.com</a>
            </div>
          </div>
          <div className="form-container">
            <h3
              id="c-heading2"
              className={isOnContactUsSectionScreen && cHeading2Class}
              style={{ opacity: 0 }}
            >
              We'd love to hear from you
            </h3>
            <p
              id="c-sub-heading2"
              className={isOnContactUsSectionScreen && cSubHeading2Class}
              style={{ opacity: 0 }}
            >
              Thank you for your interest in our services. Please provide the
              following information <br />
              about your business needs. We will contact you within the next 24
              hours.
            </p>
            <div id="errorContainer" className="alert alert-danger collapse">
              <a id="messageClose" className="close" href="#">
                &times;
              </a>
              <div id="errorMessage"></div>
            </div>
            <div
              id="contact-form"
              className={
                isOnContactUsSectionScreen
                  ? "contact-form " + contactFormClass
                  : "contact-form"
              }
              name="contactForm"
            >
              <div className="form-group">
                <input
                  id="tbName"
                  required
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Name *"
                  value={contactMessage.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  id="tbEmail"
                  required
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email *"
                  value={contactMessage.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group custom-react-phone">
                <div className="react-tel-input">
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    onChange={(value) => {
                      handleChange({ target: { name: "phone", value: value } });
                    }}
                    inputclassName="tel-class"
                    placeholder={"+1"}
                    country={"us"}
                    value={contactMessage.phone}
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  id="tbCompany"
                  className="form-control"
                  type="text"
                  placeholder="Company Name"
                  name="companyName"
                  value={contactMessage.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  placeholder="Country"
                  name="country"
                  value={contactMessage.country}
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Country
                  </option>
                  <option>US - United States</option>
                </select>
              </div>
              <div className="form-group">
                <textarea
                  id="tbNote"
                  required
                  className="form-control"
                  rows="3"
                  placeholder="Note *"
                  name="note"
                  value={contactMessage.note}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="btn-holder">
                <button
                  className="sas-link"
                  id="btnSend"
                  style={{ margin: "0px auto" }}
                  onClick={handleSubmit}
                >
                  <span style={{ marginRight: "10px" }}>
                    {loading ? "Sending..." : "Send Message"}
                  </span>{" "}
                  &nbsp;
                  <i
                    style={{ fontSize: "16px", marginRight: "0px" }}
                    className="fas fa-arrow-right"
                  ></i>
                </button>
              </div>
              <div
                className={
                  isMessageSent
                    ? "alert alert-success"
                    : "alert alert-success hidden"
                }
              >
                Message Sent!
              </div>
              <div
                className={
                  errorMessage !== ""
                    ? "alert alert-danger"
                    : "alert alert-danger hidden"
                }
              >
                {errorMessage}
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
    </div>
  );
}

export default ContactUs;

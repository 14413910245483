import React from "react";

function SecurityMobile() {
  return (
    <div id="accordion" className="portfolio accordion-text">
      <h2
        id="n-heading"
        className={"news-and-updates-title text-center animateSlideUp"}
      >
        Commitment to Security
      </h2>
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Meet Industry Compliance Standards
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            We take compliance very seriously, which is why we’ve earned both
            SOC 2 Type 1 and SOC 2 Type 2 certifications:
            <ul>
              <li>
                Type 1 ensures that our systems and controls are designed
                effectively to meet stringent security requirements.
              </li>
              <li>
                Type 2 shows that we maintain these controls over time, ensuring
                consistent performance.
              </li>
            </ul>
            <p className="accordion-text">
              We’ve also partnered with <b>Secureframe</b>, a leading platform
              for compliance automation, to stay ahead of evolving regulations.
              Secureframe helps us ensure our processes are always current,
              scalable, and audit-ready, saving time and reducing risks for you.
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Strengthening Security with Penetration Testing
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div class="card-body">
            To proactively protect against potential threats, we conduct regular
            penetration tests. These tests simulate real-world cyberattacks,
            allowing us to identify and address vulnerabilities before they can
            be exploited. This isn’t a one-time effort—it’s an ongoing
            commitment to ensuring your data remains safe and secure. With us,
            you can rest easy knowing we’re always ahead of potential threats.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Guaranteeing Data Integrity with WORM Compliance
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            For industries that rely on unalterable record-keeping, such as
            finance, healthcare, or legal, we’ve implemented WORM-compliant
            (Write Once, Read Many) storage. This ensures that once data is
            written, it can’t be changed or tampered with, meeting critical
            regulatory requirements for record retention. With this level of
            data integrity, you can trust that your most important information
            is safe and reliable for as long as needed.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Ensuring Business Continuity with BCP Compliance
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            Downtime isn’t an option. Our Business Continuity Planning (BCP)
            ensures that we’re always operational, even during unexpected
            disruptions like natural disasters, cyberattacks, or system
            failures. We’ve developed, tested, and refined our continuity plans
            to minimize downtime and ensure your business is never left
            vulnerable. With us, you get a partner who’s always prepared for the
            unexpected.
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              The Ultimate Hybrid Solution
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <div class="card-body">
            SoftwareAlgo seamlessly integrates into both Public Cloud and
            On-Premise environments, offering unmatched flexibility and
            performance. Whether you’re leveraging the scalability of the cloud
            or ensuring compliance and control with on-premise systems,
            SoftwareAlgo adapts to meet your unique needs.
            <label className="sub-header accordion-text">
              With secure, scalable, and cost-efficient integration,
              SoftwareAlgo empowers your business to thrive in any IT landscape.
              Optimize your operations and future-proof your infrastructure with
              a solution built for versatility and growth.
            </label>
            <div className="row">
              <div className="col-md-4">
                <ul>
                  <li>AWS</li>
                  <li>GCP</li>
                  <li>Docker</li>
                  <li>Insider Trading</li>
                  <li>Azure</li>
                  <li>On-Prem</li>
                  <li>Mini Manipulation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Why Choose Us?
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            When you work with SoftwareAlgo Solutions you’re not just getting a
            service provider—you’re gaining a trusted partner. Our commitment to
            security and compliance is built into everything we do.
            <label className="sub-header accordion-text">We combine:</label>
            <ul>
              <li>Industry-leading SOC 2 Type 1 and Type 2 certifications.</li>
              <li>Ongoing penetration testing for proactive protection.</li>
              <li>Reliable WORM compliance for tamper-proof data storage. </li>
              <li>Comprehensive business continuity plans for resilience.</li>
              <li>
                The power of Secureframe to keep compliance seamless and
                scalable.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityMobile;

import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <footer className="footer clearfix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="text-center" style={{ color: "#212529" }}>
                <label>
                  &copy; {currentYear}. Software Algo Solutions. All Rights
                  Reserved. &nbsp;
                </label>
                <Link to="/termsOfUse" target="_blank">
                  Terms of Use. &nbsp;
                </Link>
                <Link to="/privacyPolicy" target="_blank">
                  Privacy Policy. &nbsp;
                </Link>
                <Link to="/criticalIncident" target="_blank">
                  Critical Incident.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

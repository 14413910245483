import React from "react";
import {
  compliance,
  taxation,
  clearing,
  client,
  clientMobile,
  ria,
  metabase,
} from "../../../../constants/sas_links";

function PortfolioMobile() {
  return (
    <div id="accordion" className="portfolio accordion-text">
      <h2
        id="n-heading"
        className={"news-and-updates-title text-center animateSlideUp"}
      >
        Portfolio
      </h2>
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              Equity, Option and Digital Asset Clearing System
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            An extensive and dynamic clearing and processing system for
            Equities, Options and Digital Assets. Real time trade and clearing
            process allows for up to the minute position, balance, P&L and
            margin calculations. Real-time clearing connectivity allows for
            instant view of trade and position reconciliation with NSCC/DTCC.
            <a className="sas-link" href={clearing} target="_blank">
              <i className="fas fa-globe-americas"></i> Visit Website
            </a>
            <label className="sub-header accordion-text">Features:</label>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>Realtime NSCC and CNS reconciliation</li>
                  <li>Realtime Position, Balance and activity</li>
                  <li>
                    Fully integrated NSCC/DTCC/CNS services including MQ and FIX
                    protocols
                  </li>
                  <li>
                    Complete OMS integration including Rest API, FIX and gRPC
                  </li>
                  <li>
                    Automated account creation/maintenance via Rest API and gRPC
                  </li>
                  <li>Corporate Action</li>
                  <li>Security and Price Master</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Stock Loan</li>
                  <li>Physical Certificate</li>
                  <li>BlueSheet</li>
                  <li>OATS</li>
                  <li>15c3</li>
                  <li>CAT</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Client Site
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              A web-based white labeled application that can be accessed
              directly by the clients. Fully integrated with automated AML/KYC
              functionality and all required data capture to automate the
              account opening process. Client site includes the following:
            </p>
            <a className="sas-link" href={client} target="_blank">
              <i className="fas fa-globe-americas"></i> Visit Website
            </a>

            <label className="sub-header accordion-text">Features:</label>
            <ul>
              <li>Account Setup</li>
              <li>Bank Linkage via PLAID</li>
              <li>ACH/Wire request</li>
              <li>Tax Filing reports</li>
              <li>Balance, Position, Activity, Margin Requirement reports</li>
              <li>Trade Confirm and Monthly Statements</li>
              <li>Invoices</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Client Mobile App
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              A mobile application version of the Broker Dealer Client site
              which can be accessed by the client anytime, anywhere. The
              application is supported by iOS, Android and Windows systems.
            </p>
            <a className="sas-link" href={clientMobile} target="_blank">
              <i className="fas fa-globe-americas"></i> Visit Website
            </a>

            <label className="sub-header accordion-text">Features:</label>
            <ul>
              <li>Position</li>
              <li>Balance</li>
              <li>Activity</li>
              <li>Margin Calls</li>
              <li>ACH/Wire Request</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Tax Processing System
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              A software created to automate tax processing, IRS submissions,
              and other tax-related activities. It is built to provide tax-time
              priorities notification and tax preparations.
            </p>
            <a className="sas-link" href={taxation} target="_blank">
              <i className="fas fa-globe-americas"></i> Visit Website
            </a>

            <label className="sub-header accordion-text">Features:</label>
            <ul>
              <li>
                Automated processing, reporting and IRS submission of 1042 and
                1099
              </li>
              <li>
                Notifications from IRS and state upon processing of returns.
              </li>
              <li>Tracking of IRS refund status</li>
              <li>
                Electronic Income Tax Return and Electronic Deferred Tax
                application
              </li>
              <li>
                Automated calculations and generation of report for Value Added
                Tax for income, expenses and interest
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              Compliance System
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              An application that provides a compliance technology platform for
              financial services. The functionality ranges from compliance risk
              monitoring, compliance tracking, reporting, updating compliance
              content and analytics.
            </p>
            <a className="sas-link" href={compliance} target="_blank">
              <i className="fas fa-globe-americas"></i> Visit Website
            </a>

            <label className="sub-header accordion-text">Features:</label>
            <div className="row">
              <div className="col-md-4">
                <ul>
                  <li>Surveillance set up and report</li>
                  <li>Compliance Task Monitor System</li>
                  <li>Exception Reports</li>
                  <li>Insider Trading</li>
                  <li>Layering</li>
                  <li>Marking the Close</li>
                  <li>Mini Manipulation</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>MOC-LOC Post</li>
                  <li>Odd Lot</li>
                  <li>Short Sale Violation</li>
                  <li>Trade Participation Report</li>
                  <li>UnDue Concentration</li>
                  <li>VCR</li>
                  <li>Wash Sale and etc.</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>Margin Monitoring System</li>
                  <li>Margin BP usage stats</li>
                  <li>Margin Requirement</li>
                  <li>Day Trade Buying Power</li>
                  <li>Time and Tick</li>
                  <li>And more...</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              RIA App
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              The platform offers connections to multiple custodians (Fidelity
              and Schwab), other RIA tools, and advisor portals providing SSO
              logins, so all your data is centralized in one application. All
              data is gathered and reported in one site eliminating the need to
              log into and compile reports from multiple providers. The platform
              also offers portfolio balancing, asset distribution, and variance
              calculations based on pre-defined risk tolerance.
            </p>
            <a className="sas-link" href={ria} target="_blank">
              Go to Website
            </a>
            <label className="sub-header accordion-text">Features:</label>
            <div className="row">
              <div className="col-md-4">
                <ul>
                  <li>Billing</li>
                  <li>Product Generator</li>
                  <li>Dynamic Reports</li>
                  <li>Capital Variance Model</li>
                  <li>Exception Reports</li>
                  <li>Trading Platform</li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul>
                  <li>Position</li>
                  <li>Balance</li>
                  <li>Transaction</li>
                  <li>RMD Reports</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed accordion-text"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              Adhoc Reporting
            </button>
          </h5>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordion"
        >
          <div class="card-body">
            <p className="accordion-text">
              Reporting provides the capabilities to generate on-demand queries
              and scheduled reports with data explicitly tailored to the user’s
              needs directly from the SAS database. Queries can either be
              written directly using T-SQL or built using the predefined
              interactive dashboards for non-technical users. This allows staff
              to get targeted answers to their questions and the ability to
              create custom dashboards and reports. This opens up the SAS data
              to users of all technical levels and gives the ability to make the
              data yours.
            </p>
            <a className="sas-link" href={metabase} target="_blank">
              Go to Website
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioMobile;
